import { model } from '@telekomconsalting/dex-guru-model'
import { FC } from 'react'

import {
  financialFormat,
  financialFormatLowValueHighPrecision,
} from '../../helpers/priceAndAmountHelpers'
import { MarketType } from '../../model'
import { getMarketDisplayNameBySymbols } from '../../utils'
import TransactionsDropdown from './TransactionsDropdown'

interface TransactionsAmountTokensProps {
  amounts: number[]
  symbols: string[]
  quantityTokensTooltip: number
  wallet?: string | null
  walletsCategories?: model.Category[] | null
  transactionAddress?: string
  transactionType: string
  isShowTransactionsDropdown?: boolean
  onAccountChange?: (account?: string) => void
  onClickTableRow?: (wallet: string, transaction: string, tab: string) => void
}

const TransactionsAmountTokens: FC<TransactionsAmountTokensProps> = (props) => {
  const {
    amounts,
    symbols,
    quantityTokensTooltip,
    wallet,
    walletsCategories,
    transactionAddress,
    transactionType,
    isShowTransactionsDropdown,
    onAccountChange,
    onClickTableRow,
  } = props

  const indexPositiveValue = amounts.findIndex((amount: number): boolean => amount > 0)
  const index = indexPositiveValue === -1 ? 0 : indexPositiveValue

  const getInnerTooltip = (): string => {
    return `<div class="tokens"><div class="amount">${amounts
      .map(
        (amount: number) =>
          `<div class="amount__value">${financialFormat(amount, {
            roundBigNumbersFrom: 'M',
          })}</div>`
      )
      .join(' ')}</div><div class="token"><div class="token__symbol">${symbols
      .map((symbol: string) => `<div class="symbol">${symbol}</div>`)
      .join(' ')}</div></div></div>`
  }

  if (amounts.length > quantityTokensTooltip) {
    return (
      <>
        <div className="amount">
          <div className="amount__value">
            <div
              key={`${index}-${amounts[index]}`}
              className="in value"
              title={financialFormatLowValueHighPrecision(amounts[index])}>
              {amounts[index] &&
                financialFormatLowValueHighPrecision(amounts[index], {
                  roundBigNumbersFrom: 'M',
                })}
            </div>
            <div
              className="amount__tokens"
              data-for="app-tooltip"
              data-tip={getInnerTooltip()}
              data-place="right">
              {amounts.length} tokens
            </div>
          </div>
        </div>
        <div className="token">
          <div className="token__symbol">
            <div
              key={symbols[index]}
              className="symbol"
              title={getMarketDisplayNameBySymbols({ symbol: symbols[index] })}>
              {getMarketDisplayNameBySymbols({ symbol: symbols[index] })}
            </div>
            <div className="symbol" />
          </div>
          {isShowTransactionsDropdown && (
            <TransactionsDropdown
              wallet={wallet}
              walletsCategories={walletsCategories}
              transactionAddress={transactionAddress}
              transactionType={transactionType}
              marketType={MarketType.lp}
              onAccountChange={onAccountChange}
              onClickTableRow={onClickTableRow}
            />
          )}
        </div>
      </>
    )
  }

  return (
    <>
      <div className="amount">
        <div className="amount__value">
          {amounts.map((amount, i) => (
            <div key={`${amount}-${i}`} className="value">
              <span className="number" title={financialFormatLowValueHighPrecision(amount)}>
                {financialFormatLowValueHighPrecision(amount)}
              </span>
            </div>
          ))}
        </div>
      </div>
      <div className="token">
        <div className="token__symbol">
          {symbols.map((symbol, i) => (
            <div
              key={`${symbol}-${i}`}
              className="symbol"
              title={getMarketDisplayNameBySymbols({ symbol })}>
              {getMarketDisplayNameBySymbols({ symbol })}
            </div>
          ))}
        </div>
        {isShowTransactionsDropdown && (
          <TransactionsDropdown
            wallet={wallet}
            walletsCategories={walletsCategories}
            transactionAddress={transactionAddress}
            transactionType={transactionType}
            marketType={MarketType.lp}
            onAccountChange={onAccountChange}
            onClickTableRow={onClickTableRow}
          />
        )}
      </div>
    </>
  )
}

export default TransactionsAmountTokens
