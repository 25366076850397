import { model } from '@telekomconsalting/dex-guru-model'
import classNames from 'classnames'
import { isArray } from 'lodash'
import { FC, useState } from 'react'

import { AmplitudeEvent } from '../../constants/amplitudeEvents'
import { ReactComponent as Filter } from '../../images/icons/filter-column.svg'
import { LiquidityProviderCategoryName, TraderCategoryName } from '../../model'
import amplitudeService from '../../services/amplitudeService'
import { CategoryIcon } from '../CategoryIcon/CategoryIcon'
import OutsideClicker from '../OutsideClicker'
import { getWalletCategory } from './helpers'

interface TransactionsDropdownProps {
  wallet?: string | null
  walletsCategories?: model.Category[] | null
  transactionAddress?: string
  className?: string
  marketType: string
  transactionType: string
  onAccountChange?: (account?: string) => void
  onClickTableRow?: (wallet: string, transaction: string, tab: string) => void
}

const TransactionsDropdown: FC<TransactionsDropdownProps> = (props: TransactionsDropdownProps) => {
  const {
    wallet,
    walletsCategories,
    transactionAddress,
    className,
    marketType,
    transactionType,
    onAccountChange,
    onClickTableRow,
  } = props

  const [isShown, setIsShown] = useState(false)
  const [justClosed, setJustClosed] = useState(false)

  const walletCategory = getWalletCategory(walletsCategories)

  const getDefaultCategory = (transactionType: string): model.Category => {
    if (transactionType === 'mint' || transactionType === 'burn') {
      return LiquidityProviderCategoryName.rooster
    }
    return TraderCategoryName.casual
  }

  const category =
    !walletCategory ||
    (isArray(walletCategory) && !walletCategory.length) ||
    walletCategory === ('noob' as model.Category)
      ? getDefaultCategory(transactionType)
      : walletCategory

  const onClickTraderProlile = (): void => {
    if (wallet && onClickTableRow && transactionAddress) {
      onClickTableRow(wallet, transactionAddress, marketType)
    }
  }

  const filterAccount = (event: React.MouseEvent<HTMLDivElement>): void => {
    const account = event.currentTarget.getAttribute('data-wallet')
    if (!account) {
      return
    }

    amplitudeService.sendEvent(AmplitudeEvent.APPLIED_QUICK_FILTER_FROM_ADDRESS_ON_TOKEN_PROFILE)

    onAccountChange && onAccountChange(account)
  }

  const open = (): void => {
    if (!justClosed) {
      setIsShown(true)
    }
    setJustClosed(false)
  }

  const close = (): void => {
    setJustClosed(isShown)
    setIsShown(false)
  }

  return (
    <>
      <div
        className={classNames(`${className || 'token'}__dots`, { visible: isShown })}
        onClick={open}>
        <span className="dot"></span>
      </div>
      <OutsideClicker clickHide={close}>
        {isShown && (
          <>
            <div className={`${className || 'token'}__dropdown`}>
              <div className="token__dropdown__item" onClick={onClickTraderProlile}>
                <span className="icon">
                  <CategoryIcon category={category} />
                </span>
                <span className="caption">Analyze Trader Profile</span>
              </div>
              {wallet && (
                <div
                  className="token__dropdown__item"
                  onClick={(event: React.MouseEvent<HTMLDivElement>): void => filterAccount(event)}
                  data-wallet={wallet}>
                  <span className="icon">
                    <Filter />
                  </span>
                  <span className="caption">Filter TX by this Trader</span>
                </div>
              )}
            </div>
          </>
        )}
      </OutsideClicker>
    </>
  )
}

export default TransactionsDropdown
