import { ReactComponent as OwnIndicator } from 'app-images/icons/ownstar.svg'
import { FC } from 'react'

const TransactionOwn: FC = () => {
  return (
    <OwnIndicator
      className="owner-mark"
      data-for="app-tooltip"
      data-tip="TX from your connected wallet"
    />
  )
}

export default TransactionOwn
